import * as React from 'react'
import Layout from 'components/layout'

import styled from 'styled-components'

const WistiaContent = styled.div`
	position: relative;
`

const TTResourceHub: React.FC = () => {
	return (
		<Layout
			title="TradeTapp Resource Hub | BuildingConnected"
			description="Watch videos about all things TradeTapp—including product deep dives, feature walkthroughs, and customer testimonials."
		>
			<WistiaContent className="wistia_channel wistia_async_u07yodhtrx mode=inline" />
		</Layout>
	)
}

export default TTResourceHub
